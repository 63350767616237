import React from "react";

const NotFoundPage = () => {
    return (
        <div style={{"height": "100vh"}}>
            <h1 style={{"color": "white", "textAlign": "center"}}>Not Found</h1>
        </div>
    );
};

export default NotFoundPage;