import React from "react";
import { useCallback } from "react";
import html2canvas from "html2canvas";

import "../css/button2.css"

import {shuffle, resize, randInt} from "../utils/utils.js"


const NimmsV2 = () => {

    const backgroundColors = ["111111" ,"F4B41A", "210070", "00539C", "D2042D", "143D59", "35E6DA", "EEA47F", "2F3C7E", "FBEAEB", "101820", "FEE715", "F96167", "FCE77D", "CCF381", "4831D4", "E2D1F9", "317773", "990011", "FCF6F5", "8AAAE5", "FFFFFF", "FF69B4", "00FFFF", "FCEDDA", "EE4E34", "ADD8E6", "00008B", "89ABE3", "EA738D", "E3B448", "CBD18F", "E3B448", "3A6B35", "EC449B", "99F443", "8A307F", "79A7D3", "8A307F", "6883BC", "CC313D", "F7C5CC", "783937", "FC766A", "783937", "F1AC88", "AA96DA", "C5FAD5", "AA96DA", "FFFFD2", "2C5F2D", "97BC62", "2BAE66", "FCF6F5", "FFE77A", "2C5F2D", "201E20", "DDC3A5", "201E20", "E0A96D", "234E70", "FBF8BE", "408EC6", "7A2048", "408EC6", "1E2761", "7A2048", "1E2761", "B85042", "E7E8D1", "B85042", "A7BEAE"]
    const textColors = ["D2042D", "143D59", "35E6DA", "EEA47F", "111111", "F4B41A", "210070", "00539C", "FBEAEB", "2F3C7E", "FEE715", "101820", "FCE77D", "F96167", "4831D4", "CCF381", "317773", "E2D1F9", "FCF6F5", "990011", "FFFFFF", "8AAAE5", "00FFFF", "FF69B4", "EE4E34", "FCEDDA", "00008B", "ADD8E6", "EA738D", "89ABE3", "CBD18F", "E3B448", "3A6B35", "E3B448", "99F443", "EC449B", "79A7D3", "8A307F", "6883BC", "8A307F", "F7C5CC", "CC313D", "FC766A", "783937", "F1AC88", "783937", "C5FAD5", "AA96DA", "FFFFD2", "AA96DA", "97BC62", "2C5F2D", "FCF6F5", "2BAE66", "2C5F2D", "FFE77A", "DDC3A5", "201E20", "E0A96D", "201E20", "FBF8BE", "234E70", "7A2048", "408EC6", "1E2761", "408EC6", "1E2761", "7A2048", "E7E8D1", "B85042", "A7BEAE", "B85042"]

    const [isPopupClosed, setIsPopupClosed] = React.useState(false);
    const [input, setInput] = React.useState("");
    const [width, setWidth] = React.useState(50);
    const [height, setHeight] = React.useState(50);
    const [marginTop, setMarginTop] = React.useState(20);
    const [randInd, setRandInd] = React.useState(0);
    

    const keyHandler = useCallback(async (event) => { 
        console.log(`Key: ${event.key}`);
        if (event.key === 'Enter') {
            let tempInput = input;

            let letters = Array.from(input);

            letters = shuffle(letters);

            let shuffledInput = letters.join('');
            
            setInput(shuffledInput);
            await new Promise(r => setTimeout(r, 1000));

            html2canvas(document.body).then((canvas) => {
                // create download element
                let out = document.createElement("a");

                // shorten file name if needed
                let file_name = tempInput
                if (tempInput.length > 200) {
                    file_name = tempInput.substring(0, 200)
                }

                // set download file name
                out.download = file_name + ".png";

                // set download link
                out.href = canvas.toDataURL("image/png");

                // download the masterpiece
                out.click();
            });

            setInput(tempInput);
        }
        else if (event.key === 'Backspace') {
            // delete last char
            setInput(prevInput => `${prevInput.substring(0, prevInput.length-1)}`);
        }
        else if (["Alt","Shift","Meta","Tab","CapsLock","Control","Escape","ArrowLeft","ArrowDown","ArrowRight","ArrowUp"].indexOf(event.key) > -1) {
            // do nothing
        }
        else {
            // write new char
            setInput(prevInput => `${prevInput}${event.key}`);
        }
    });

    React.useEffect(() => {
        if (input.length < 15) {
            setWidth(85)
            setHeight(80)
            setMarginTop(10)
        } else if (input.length < 20) {
            setWidth(85)
            setHeight(86)
            setMarginTop(7)
        } else if (input.length < 30) {
            setWidth(90)
            setHeight(90)
            setMarginTop(5)
        } else {
            setWidth(96)
            setHeight(96)
            setMarginTop(2)
        }
        document.addEventListener('keydown', keyHandler);
        return () => {
            document.removeEventListener('keydown', keyHandler);
        };
    }, [keyHandler]);

    React.useEffect(() => {
        setRandInd(randInt(0,72))
        if (window.localStorage.getItem('isPopupClosed') == "true") {
            setIsPopupClosed(true)
        }
    }, []);

    if (document.readyState === 'complete') {
        resize();
    } else {
        window.onload = resize();
    }
    
    return (
        <div style={{"width": "100vw", "height": "100vh", "backgroundColor": "#" + backgroundColors[randInd]}}>
            {
                isPopupClosed ? 
                    <div id="outer" style={{"display": "flex", "justifyContent": "center", "alignItems": "center", "width": width.toString() + "vw", "height": height.toString() + "vh", "margin": "auto", "textAlign": "center"}}>
                        <div style={{"display": "flex", "justifyContent": "center", "alignItems": "center", "width": width.toString() + "vw", "height": height.toString() + "vh", "margin": "auto", "marginTop": marginTop.toString() + "vh"}}>
                            <div className="text" style={{"color": "#" + textColors[randInd]}}>
                                {input}
                            </div>
                        </div>
                    </div>
                :
                    <div style={{"display": "flex", "alignItems": "center", "margin": "auto"}}>
                        <div style={{padding: "10px", "display": "flex", "justifyContent": "space-evenly", "flexDirection": "column", "width": "550px", "height": "400px", "backgroundColor": "#" + textColors[randInd], "color": "red", "boxShadow": "0 16px 32px 0 rgba(0,0,0,0.2)", "borderRadius": "5px", "margin": "auto", "marginTop": "30vh"}}>
                            <h2 style={{"justifyContent": "center", "alignItems": "center", "textAlign": "center", "color": "#" + backgroundColors[randInd]}}>
                                Now It Makes More Sense (NIMMS)
                            </h2>
                            
                            <div style={{"marginTop": -30, "marginBottom": 0,"justifyContent": "center", "alignItems": "center", "textAlign": "center", "color": "#" + backgroundColors[randInd]}}>
                                This project is to show, "Bullshit is bullshit". Sometimes different representations -abstraction- help people to understand problems in an easy way. 
                                For instance, graph representation can help solving "The Knight's Tour Puzzle". 
                                You can check <a href="https://teachinglondoncomputing.files.wordpress.com/2015/05/wkshp-6-slides-graphs.pdf" style={{"color": "#" + backgroundColors[randInd]}}> Paul Curzon's slides</a> for further explanation. 
                                With the help of <b>NIMMS</b>, you can show people's own bullshit to them in a different representation, which will make much more sense than their bullshit.
                            </div>

                            <h2 style={{"marginTop": 0, "marginBottom": 0, "justifyContent": "center", "alignItems": "center", "textAlign": "center", "color": "#" + backgroundColors[randInd]}}>
                                How To Use
                            </h2>

                            <div style={{"marginTop": -10, "marginBottom": 0, "justifyContent": "center", "alignItems": "center", "textAlign": "center", "color": "#" + backgroundColors[randInd]}}>
                                After closing the popup, just type the bullshit. Press `Enter`(↩) when you are done. <b>NIMMS</b> version will be downloaded directly. For questions: pskirp@gmail.com
                            </div>

                            <div style={{"display": "flex", "justifyContent": "space-evenly", "align-items": "center"}}>
                                <button class="my-button" onClick={() => {setIsPopupClosed(true); setInput("");}} style={{"display": "inline-block", "vertical-align": "middle"}}>
                                    Remind Me Later
                                </button>
                                <button class="my-button" onClick={() => {setIsPopupClosed(true); setInput(""); window.localStorage.setItem('isPopupClosed', "true")}} style={{"display": "inline-block", "vertical-align": "middle"}}>
                                    Don't Show Again
                                </button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default NimmsV2;