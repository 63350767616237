import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './containers/home_page'
import BaldessariPage from "./containers/baldessari_page";
import GenerateMessage from "./containers/generate_message";
import Nimms from "./containers/nimms";
import NimmsV2 from "./containers/nimmsV2";
import NotFoundPage from "./containers/not_found_page";


import './App.css';

const App = () => {
  return(
  <div style={{"width": "100vw", "height": "100vh", "backgroundColor": "#111111"}}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>} />

          <Route path='/dots' element={<BaldessariPage/>}/>
          
          <Route path='/generate-message' element={<GenerateMessage/>}/>

          <Route path='/now-it-makes-more-sense' element={<Nimms/>}/>

          <Route path='/now-it-makes-more-sense-V2' element={<NimmsV2/>}/>
          
          <Route path="/*" element={<NotFoundPage/>} />

        </Routes>
      </Router>
  </div>
)}

export default App;