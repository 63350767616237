export { randInt, CopyUrl, shuffle, resize };

function randInt(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
}

function CopyUrl(text){
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(function() {
        console.log("succeeds")
      }).catch(function() {
        console.log("failed")
      });
    } else {
      // Here's where you put the fallback code for older browsers.
      console.log("old browser, shit! Sorry :/")
    }
}

function shuffle(array) {
  let randomIndex, currentIndex = array.length;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function resize(){
  const isOverflown = ({ clientWidth, scrollWidth, clientHeight, scrollHeight}) => scrollWidth > clientWidth || scrollHeight > clientHeight

  const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {
    (elements || [element]).forEach(el => {
      let i = minSize
      let overflow = false

          const parent = el.parentNode

      while (!overflow && i < maxSize) {
          el.style.fontSize = `${i}${unit}`
          overflow = isOverflown(parent)
          el.style.lineHeight = ((el.style.fontSize).slice(0, -2))*1.2+"px"
        if (!overflow) i += step
      }

      el.style.fontSize = (`${i - step - 1}${unit}`)
    })
  }

  resizeText({
    elements: document.querySelectorAll('.text'),
    step: 0.5
  })
}