import * as faceapi from 'face-api.js';
import React from 'react';

const BaldessariPage = () => {

  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [howGray, setHowGray] = React.useState("0");

  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 640;
  const canvasRef = React.useRef();

  const colors = ["#AF0000", "#00AF00", "#0000AF", "#AF00AF", "#CFCF00", "#00AFAF"]

  React.useEffect(() => {
    startVideo();
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';

      Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(setModelsLoaded(true));
    }
    loadModels();
  }, []);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  }

  const handleVideoOnPlay = () => {
    const displaySize = {
      width: videoWidth,
      height: videoHeight
    }
    var ctx = canvasRef.current.getContext('2d');
    
    setInterval(async () => {
      const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.SsdMobilenetv1Options());
      const resizedDetections = faceapi.resizeResults(detections, displaySize);
      
      if (resizedDetections.length > 0) {
        setHowGray("100");
        resizedDetections.sort((a, b) => (a.box.x > b.box.x) ? 1 : -1);
      }
      else {
        setHowGray("0");
      }

      ctx.clearRect(0, 0, videoWidth, videoHeight);
      for (let i = 0; i < resizedDetections.length; i++) {
        var box = resizedDetections[i].box
        var circle = new Path2D();
        circle.arc(box.x + box.width/2, box.y + box.height/2, box.height/2, 0, 2 * Math.PI);
        ctx.fillStyle = colors[i%6]
        ctx.fill(circle)
      }      
    }, 100)
  }

  return (

    <div>
      <div style={{"display": "flex", "justify-content": "space-evenly", "align-items": "center", "height": "20vh"}}>
        <div style={{"display": "inline-block", "vertical-align": "middle"}}>
            <button class="glitch-button" onClick={() => {window.location.href='/';}}>Back To Home</button>
        </div>
      </div>
     
      <div>
        {
          modelsLoaded ?
            <div style={{ 
              "position": "fixed",
              "top": "50%",
              "left": "50%",
              "transform": "translate(-50%, -50%)"
            }}>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '1px' }}>
                <video ref={videoRef} height={videoHeight} width={videoWidth} onPlay={handleVideoOnPlay} style={{ borderRadius: '1px', filter: 'grayscale('+ howGray +'%)' }} />
                <canvas ref={canvasRef} height={videoHeight} width={videoWidth} style={{ position: 'absolute' }} />
              </div>
            </div>
            :
            <div style={{ 
              "position": "fixed",
              "top": "50%",
              "left": "50%",
              "transform": "translate(-50%, -50%)"
            }}>loading...</div>
        }
      </div>
    </div>
  );
}

export default BaldessariPage;
