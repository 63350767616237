import React from "react";

import {randInt, CopyUrl} from "../utils/utils.js"

import "../css/button.css"

const GenerateMessage = () => {

    const [message, setMessage] = React.useState("");

    const list_intro = ["Hey.", "Hello.", "Hi!"]
    const list_explanation = [" Blah. Blah. Blah. ", " Great ideas. Great ideas. Great ideas. Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas.Great ideas. Great ideas. Great ideas. ", " We need you. We need you. We need you. "]
    const list_bye = ["Have a nice day.", "See you.", "Take care!"]

    React.useEffect(() => {
        let generated_message = list_intro[randInt(0,3)] + list_explanation[randInt(0,3)] + list_bye[randInt(0,3)]
        setMessage(generated_message)
        CopyUrl(generated_message)
    }, []);

    return (
        <div>
            <div style={{"display": "flex", "justify-content": "space-evenly", "align-items": "center", "height": "30vh"}}>
                <div style={{"display": "inline-block", "vertical-align": "middle"}}>
                    <button class="glitch-button" onClick={() => {window.location.href='/';}}>Back To Home</button>
                </div>
            </div>

            <div style={{"display": "flex", "justify-content": "space-evenly", "align-items": "center", "height": "70vh"}}>
                <div style={{"color": "white"}}>
                    <h3 style={{"text-align": "center", "width": "90vw"}}>{message}</h3>
                </div>
                <div style={{"color": "white"}}>
                    
                </div>
            </div>
        </div>
    );
};

export default GenerateMessage;